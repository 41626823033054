import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useLanguage } from './LanguageContext';
import Cookies from 'js-cookie';

const LoginPage = () => {
  const { language } = useLanguage();
  const strings = require(`./${language}.json`);
  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);

  const history = useHistory();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [apiErrors, setApiErrors] = useState([]);

  useEffect(() => {
    const authToken = Cookies.get('authToken');
    if (authToken) {
      history.push(`/account`);
    }
  }, [history]); // Пустой массив зависимостей означает, что эффект будет выполняться только при монтировании компонента

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        window.location.origin === 'http://localhost:3000'
          ? 'http://localhost:8000/api/auth/token/login/'
          : `${window.location.origin}/api/auth/token/login/`,
        formData
      );
      const { auth_token } = response.data;
      let expires;
      if (window.location.origin === 'https://dimakranz.com') {
        expires = 10 / (60 * 24); // 10 минуты
      } else if (window.location.origin === 'https://skillsytesting.co') {
        expires = 8 / 24; // 8 часов
      } else if (window.location.origin.includes('localhost')) {
        expires = 10 / (60 * 24); // 10 минуты
        // expires = 10 / (60 * 60 * 24); // 10 секунд
      } else {
        // Default expiration time if needed
        expires = 1; // 1 день, for example
      }

      Cookies.set('authToken', auth_token, { expires: expires });
      // Cookies.set('authToken', auth_token, { expires: 10 / (60 * 60 * 24) }); // 10 секунд
      // Cookies.set('authToken', auth_token, { expires: 3 / (60 * 24) }); // 3 минуты
      // Cookies.set('authToken', auth_token, { expires: 8 / 24 }); // 8 часов
      // Check if there's a stored path and redirect to it
      const redirectPath = Cookies.get('redirectAfterLogin');
      if (redirectPath) {
        Cookies.remove('redirectAfterLogin');
        history.push(redirectPath);
      } else {
        history.push('/account'); // default redirect path
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setApiErrors(error.response.data.non_field_errors || ['Login failed. Please check your credentials.']);
      } else {
        console.error('Login failed:', error);
      }
    }
  };

  return (
    <div>
      <h2 className='login'>{strings.login}</h2>

      <form onSubmit={handleSubmit}>
        <input
          type="email"
          name="email"
          placeholder={strings.email}
          value={formData.email}
          onChange={handleChange}
          required
        />
        <br />
        <input
          type="password"
          name="password"
          placeholder={strings.password}
          value={formData.password}
          onChange={handleChange}
          required
        />
        <br />
        {apiErrors.length > 0 && (
          <div>
            {strings.loginFailed}
          </div>
        )}
        <button type="submit">{strings.login}</button>
      </form>
      <p>{strings.haventAccount} <Link to="/sign_up">{strings.registration}</Link></p>
    </div>
  );
};

export default LoginPage;