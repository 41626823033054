import React, { useEffect } from 'react';
import { useLanguage } from './LanguageContext';
import { useHistory, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

const AuthorisationError = () => {
    const { language } = useLanguage();
    const strings = require(`./${language}.json`);
    const history = useHistory();
    const location = useLocation();

    const handleAuthorization = () => {
        // Store the current path before redirecting to the login page
        Cookies.set('redirectAfterLogin', location.pathname);
        Cookies.remove('authToken');
        history.push('/');
    };

    useEffect(() => {
        document.documentElement.lang = language;
    }, [language]);

    return (
        <div>
            <h1>{strings.authorisationError}</h1>
            <p>{strings.logInAgain}</p>
            <button onClick={handleAuthorization}>{strings.goToAuthorization}</button>
        </div>
    );
}

export default AuthorisationError;