import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { useLanguage } from './LanguageContext';
import NotFoundPage from './NotFoundPage';
import LoadingPage from './LoadingPage';

function WelcomePage() {
  const history = useHistory();
  const { userId } = useParams();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [errorText, setErrorText] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [notFound, setNotFound] = useState(false);
  const [linkInactive, setLinkInactive] = useState(false);
  const [loading, setLoading] = useState(true);

  const { language } = useLanguage();
  const strings = require(`./${language}.json`);

  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const response = await axios.get(
          `${window.location.origin === 'http://localhost:3000' ? 'http://localhost:8000' : window.location.origin}/api/user/${userId}/welcome/`
        );

        if (!response.data || !response.data.company) {
          throw new Error('Invalid response data');
        }

        setLoading(false); // Устанавливаем состояние загрузки как завершенное
      } catch (error) {
        console.error('Error:', error);

        if (error.response) {
          if (error.response.status === 403 && error.response.data.error === "Link is not active") {
            setLinkInactive(true); // Устанавливаем состояние для отображения сообщения о неактивной ссылке
          } else if (error.response.status === 404) {
            setNotFound(true); // Устанавливаем состояние для отображения NotFoundPage
          } else {
            setErrorText(error.response.data.detail || strings.errorSendingRequest);
          }
        } else {
          setErrorText(strings.errorSendingRequest);
        }
        setLoading(false); // Устанавливаем состояние загрузки как завершенное
      }
    };

    fetchCompany();
  }, [userId, strings.errorSendingRequest]);

  const handleNextPage = async () => {
    try {
      if (!email || !firstName || !lastName || !selectedLanguage) {
        setErrorText(strings.fillRequiredFields);
        return;
      }

      if (!validateEmail(email)) {
        setErrorText(strings.invalidEmail);
        return;
      }

      const formData = new FormData();
      formData.append('email', email);
      formData.append('first_name', firstName);
      formData.append('last_name', lastName);
      formData.append('linkedin', linkedin);
      formData.append('language', selectedLanguage);

      const response = await axios.post(
        `${window.location.origin === 'http://localhost:3000' ? 'http://localhost:8000' : window.location.origin}/api/user/${userId}/welcome/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (!response.data || !response.data.user_id) {
        throw new Error('Invalid response data');
      }

      history.push(`/user/${userId}/question/${response.data.user_id}`);
    } catch (error) {
      console.error('Error:', error);
      if (error.response && error.response.data && error.response.data.detail === 'User session already exists') {
        setErrorText(strings.CookieError);
      } else if (error.response && error.response.status === 400) {
        setErrorText(error.response.data.detail);
      } else {
        setErrorText(strings.errorSendingRequest);
      }
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  if (loading) {
    return <LoadingPage />;
  }

  if (notFound) {
    return <NotFoundPage />; // Отображаем NotFoundPage, если установлено состояние notFound
  }

  if (linkInactive) {
    return (
      <div className="Welcome-form">
        <h1>{strings.welcome}</h1>
        <h3>{strings.invitesYou}</h3>
        <p style={{ color: 'red' }}>{strings.LinkIsNotActive}</p>
      </div>
    );
  }

  return (
    <div className="Welcome-form">
      <h1>{strings.welcome}</h1>
      {errorText && <p style={{ color: 'red' }}>{errorText}</p>}
      <h3>{strings.invitesYou}</h3>
      <p>{strings.enterFirstAndLastName}</p>
      <input type="email" placeholder={strings.email} value={email} onChange={(e) => setEmail(e.target.value)} />
      <br />
      <input type="text" placeholder={strings.firstName} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
      <br />
      <input type="text" placeholder={strings.lastName} value={lastName} onChange={(e) => setLastName(e.target.value)} />
      <br />
      <input type="text" placeholder={strings.linkedin} value={linkedin} onChange={(e) => setLinkedin(e.target.value)} />
      <br />
      <h4>{strings.selectLanguage}</h4>
      <label>
        <input type="checkbox" checked={selectedLanguage === "ru"} onChange={() => setSelectedLanguage("ru")} /> {strings.languageRu}
      </label>
      <label>
        <input type="checkbox" checked={selectedLanguage === "en"} onChange={() => setSelectedLanguage("en")} /> {strings.languageEn}
      </label>
      <br />
      <button onClick={handleNextPage}>{strings.startTest}</button>
    </div>
  );
}

export default WelcomePage;