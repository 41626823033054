import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { useLanguage } from './LanguageContext';

function IntroductionPage({ onStartTest }) {
  const { language } = useLanguage();
  const strings = require(`./${language}.json`);
  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);
  return (
    <div style={{ marginBottom: '10px' }}>
      <p style={{ color: "#fa5400" }}>{strings.ClosingWarning}</p>
      <h1>{strings.welcome}</h1>
      <p>{strings.attention}<br /><br />
      {strings.importantNote}<br /><br />
      {strings.beHonest}<br /><br />
      {strings.beSincere}<br /><br />
      {strings.buttonText}</p>
      <button onClick={onStartTest}>{strings.startTest}</button>
    </div>
  );
}

function Questions1() {
  const history = useHistory();
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  useEffect(() => {
    // Прокручиваем до верха страницы при изменении currentQuestion
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentQuestion]);
  const [selectedOption, setSelectedOption] = useState("");
  const { userId, data_userId } = useParams();
  const [errorText, setErrorText] = useState('');
  const [showIntroduction, setShowIntroduction] = useState(true);

  const { language } = useLanguage();
  const strings = require(`./${language}.json`);
  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    axios.get(
      `${window.location.origin === 'http://localhost:3000'
        ? 'http://localhost:8000/api/questions/'
        : `${window.location.origin}/api/questions/`
      }?user_id=${data_userId}`
    )
      .then(response => setQuestions(response.data))
      .catch((error) => {
        if (error.response) {
          console.error(strings.error , error);
          setErrorText(strings.unexpectedErrorOccurred);
        } else if (error.request) {
          console.error(strings.error, error.request);
          setErrorText(strings.requestFailed);
        } else {
          console.error(strings.error, error.message);
          setErrorText(strings.unexpectedErrorOccurred);
        }
      });
  }, [data_userId, strings]);

  const handleNext = async () => {
    try {
      const answer = {
        answer: selectedOption,
        user: data_userId
      };

      await axios.post(
        `${window.location.origin === 'http://localhost:3000'
          ? `http://localhost:8000/api/questions/${userId}/${questions[currentQuestion].id}/answers/`
          : `${window.location.origin}/api/questions/${userId}/${questions[currentQuestion].id}/answers/`
        }`,
        answer,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (currentQuestion === questions.length - 1) {
        history.push(`/user/${userId}/second-questions/${data_userId}`);
      } else {
        setCurrentQuestion(currentQuestion + 1);
        setSelectedOption("");
      }
    } catch (error) {
      if (error.response.status === 500) {
        // Обработка ошибки 500
        console.error('Ошибка 500. Внутренняя ошибка сервера:', error);
        setErrorText(strings.errorServer500);
      }
      else if (error.response.status === 404) {
        // Обработка ошибки 404
        console.error('Ошибка 404. Страница не найдена', error);
        setErrorText(strings.errorQuestionNotFound);
      }
      else if (error.response.status === 400) {
        if (error.response.status === 400 && error.response.data.detail === "User has already answered this question.") {
          setErrorText(strings.errorAlreadyAnswered);
        }
        else if (error.response.data && error.response.data.detail === 'Вы не можете отвечать на один вопрос несколько раз') {
          // Обработка ошибки "Maximum email count reached"
          console.error('Ошибка: Вы не можете отвечать на один вопрос несколько раз');
          setErrorText(strings.errorCannotAnswerMultipleTimes);
        }
        else if (error.response.data && error.response.data.detail === 'User not registered with this link') {
          // Обработка ошибки "Maximum email count reached"
          console.error('Ошибка: User not registered with this link');
          setErrorText(strings.errorUserNotRegistered);
        }
      }
      else {
        console.error('Ошибка:', error);
        setErrorText(strings.unexpectedErrorOccurred);
      }
    }
  };

  const handleStartTest = () => {
    setShowIntroduction(false);
  };

  return (
    <div className="question-container">
      {showIntroduction ? (
        <IntroductionPage onStartTest={handleStartTest} />
      ) : (
        <>
          <p style={{ color: "#fa5400" }}>{strings.ClosingWarning}</p>
          <h1>{questions[currentQuestion]?.question_numbering}</h1>
          <h4>{questions[currentQuestion]?.question_text.split('\n')[0]}</h4>
          <div>
            {/* Отображение вариантов ответов */}
            {questions[currentQuestion]?.question_text.split('\n').slice(1).map((option, index) => (
              <React.Fragment key={index}>
                <input
                  type="radio"
                  id={`option${index + 1}`}
                  name="option"
                  value={index + 1}
                  onChange={() => handleOptionChange((index + 1).toString())}
                  checked={selectedOption === (index + 1).toString()}
                />
                <label htmlFor={`option${index + 1}`}>{option}</label><br />
              </React.Fragment>
            ))}
          </div>
          <button onClick={handleNext}>{strings.next}</button>
          {errorText && <p style={{ color: 'red' }}>{errorText}</p>}
        </>
      )}
    </div>
  );
}

export default Questions1;