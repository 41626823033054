import React from 'react';
import { css } from '@emotion/react';
import { SyncLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function LoadingPage() {
  return (
    <div className="loading-page">
      <SyncLoader color={'#fa5400'} css={override} size={25} />
      <p>Loading...</p>
    </div>
  );
}

export default LoadingPage;