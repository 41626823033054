import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { useLanguage } from '../LanguageContext';
import './styles.css'; // Подключите стили

const LanguageSelector = () => {
    const { setLanguage } = useLanguage();

    const changeLanguage = (selectedLanguage) => {
        setLanguage(selectedLanguage);
    };

    return (
        <div>
            <span style={{ marginRight: 20, cursor: 'pointer' }} onClick={() => changeLanguage('en')}>
                En
            </span>
            <span style={{ cursor: 'pointer', marginRight: '20px' }} onClick={() => changeLanguage('ru')}>
                Ru
            </span>
        </div>
    );
};

const Header = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const history = useHistory();

    const checkAuthToken = () => {
        const authToken = Cookies.get('authToken');
        setIsAuthenticated(!!authToken);
    };

    useEffect(() => {
        checkAuthToken();
        const unlisten = history.listen(() => {
            checkAuthToken();
        });
        return () => {
            unlisten();
        };
    }, [history]);

    const handleLogout = async () => {
        try {
            await axios.post(
                window.location.origin === 'http://localhost:3000'
                    ? 'http://localhost:8000/api/auth/token/logout/'
                    : `${window.location.origin}/api/auth/token/logout/`,
                {},
                {
                    headers: {
                        Authorization: `Token ${Cookies.get('authToken')}`,
                    },
                }
            );
            Cookies.remove('authToken');
            setIsAuthenticated(false);
            history.push('/');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            checkAuthToken();
        }
    }, [isAuthenticated]);

    return (
        <>
            <header className="header-container">
                <div className="header-content">
                    <a href="/">
                        <img src="/skillsy_logo.png" alt="Skillsy Logo" className="logo" />
                    </a>
                    <div className="header-right">
                        <LanguageSelector />
                        {isAuthenticated && (
                            <>
                                <a href="/settings">
                                    <img src="/settings.png" alt="Settings" className="settings-icon" />
                                </a>
                                <span onClick={handleLogout} className="logout-button">
                                    Logout
                                </span>
                            </>
                        )}
                    </div>
                </div>
            </header>
            <div className="header-offset"></div> {/* Элемент для отступа */}
        </>
    );
};

export default Header;