import React, { useEffect, useState } from 'react';
import { useLanguage } from './LanguageContext';
import Cookies from 'js-cookie';

const Result = () => {
  const { language } = useLanguage();
  const strings = require(`./${language}.json`);

  const [hasLastTestLink, setHasLastTestLink] = useState(false);

  useEffect(() => {
    document.documentElement.lang = language;
    const lastTestLink = Cookies.get('lastTestLink');
    setHasLastTestLink(!!lastTestLink);
  }, [language]);

  const handleReturnToMainScreen = () => {
    Cookies.remove('lastTestLink');
    window.location.href = '/account';
  };

  return (
    <div>
      <h1>{strings.resultThankYou}</h1>
      {hasLastTestLink && (
        <button onClick={handleReturnToMainScreen}>{strings.returnToMainScreen}</button>
      )}
    </div>
  );
};

export default Result;